<template>
  <div class="app-search" id="app-search">
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-search {
  box-sizing: border-box;
  padding: 22px 16px 0;
}
</style>
