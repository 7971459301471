import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import http from "./http";
import apis from "./apis";

import "./element-variables.scss";
import Element from "element-ui";

// Vue.use(Element, {size: 'small'});
Vue.use(Element);

Vue.prototype.$apis = apis;
Vue.prototype.$http = http.init();

// collapse 展开折叠
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)

import OsSearch from '@/components/OsSearch'
Vue.component('OsSearch', OsSearch)
import OsSearchForm from '@/components/OsSearchForm'
Vue.component('OsSearchForm', OsSearchForm)
import OsTableWrap from '@/components/OsTableWrap'
Vue.component('OsTableWrap', OsTableWrap)
import OsPage from '@/components/OsPage'
Vue.component('OsPage', OsPage)

//统一退出 方法
Vue.prototype.$logout = function () {
  store.commit('setToken', '')
  store.commit('setUser', {})
  router.replace('/login')
}

// Vue.config.productionTip = false;

import { system } from "@/config";
// 设置浏览器标题
document.title = system.titleName
// 设置浏览器icon
let favicon = document.querySelector('link[rel="icon"]');
if (favicon !== null) {
  favicon.href = system.icon;
} else {
  favicon = document.createElement("link");
  favicon.rel = "icon";
  favicon.href = system.icon;
  document.head.appendChild(favicon);
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
