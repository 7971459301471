<!--
 * @Description: 描述
 * @FilePath: \vue2\src\components\OsTableWrap.vue
-->
<template>
  <div class="table-wrap" id="table-wrap">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.table-wrap {
  box-sizing: border-box;
  padding: 0 16px;

  ::v-deep  .el-table {
    border-radius: 3px;
    .el-table__cell {
      padding: 1px 0;
    }
    th {
      padding: 10px 0 !important;
      color: #606266;
      background-color: #f5f5f5;
    }
    .danger-button {
      color: #f56c6c;
    }
    .info-button {
      color: #909399;
    }
    .warning-button {
      color: #e6a23c;
    }
  }
}
</style>
