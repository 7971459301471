// 创建必填规则
function createRequiredRule(label, isSelect = false) {
  const way = isSelect ? '选择' : '输入'

  return {
    required: true,
    message: `请${way}${label}`,
    trigger: isSelect ? 'change' : 'blur',
  }
}

export { createRequiredRule }
