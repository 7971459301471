var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{style:({ display: 'flex', height: '100%', alignContent: 'center' })},[_c('OsSidebar',{attrs:{"width":_vm.isCollapse ? _vm.asideCollapseWidth : _vm.asideWidth,"isCollapse":_vm.isCollapse},on:{"collaps":function($event){_vm.isCollapse = true}}}),_c('div',{staticClass:"content-wrap",style:({
        width: `calc(100vw - ${
          _vm.isCollapse ? _vm.asideCollapseWidth : _vm.asideWidth
        }px)`,
      })},[(_vm.isCollapse)?_c('i',{staticClass:"el-icon-s-unfold",style:({
          'line-height': `${_vm.headerHeight}px`,
        }),on:{"click":function($event){_vm.isCollapse = false}}}):_vm._e(),_c('OsHeader',{style:(_vm.headerStyle),attrs:{"headerHeight":_vm.headerHeight},on:{"show-change-password":function($event){_vm.isShowChangePasswordDialog = true}}}),_c('div',{staticClass:"page-container wrapper-md",style:({ height: `calc(100% - ${_vm.headerHeight}px)` }),attrs:{"id":"page-container"}},[_c('router-view')],1)],1)],1),_c('ChangePasswordDialog',{attrs:{"show":_vm.isShowChangePasswordDialog},on:{"update:show":function($event){_vm.isShowChangePasswordDialog=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }