import Vue from 'vue'
import Vuex from 'vuex'
import CreatePersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    CreatePersistedstate({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    TOKEN: '',
    USER: {},
  },
  mutations: {
    setToken(state, value) {
      state.TOKEN = value
    },
    setUser(state, value) {
      state.USER = value
    },
  },
  actions: {
  },
  modules: {
  }
})
