<template>
  <div class="app-page" id="app-page">
    <!-- {{ mTotal }} -->
    <el-pagination
      :current-page="mCurrentPage"
      :page-size="mPageSize"
      :total="mTotal"
      :page-sizes="[15, 30, 50, 100]"
      layout="slot, prev, pager, next, sizes, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
    >
      <!-- 截止2021年12月14日，element-plus的总页数会显示成 “共 {total} 条”没法切换过去，这里先用自定义插槽覆盖掉 -->
      <span class="el-pagination__total">共 {{ mTotal }} 条</span>
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: () => 1,
    },

    pageSize: {
      type: Number,
      default: () => 10,
    },

    total: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      mCurrentPage: this.currentPage,
      mPageSize: this.pageSize,
      mTotal: this.total,
    };
  },
  watch: {
    currentPage: {
      immediate: true,
      handler() {
        this.mCurrentPage = this.currentPage;
      },
    },
    pageSize: {
      immediate: true,
      handler() {
        this.mPageSize = this.pageSize;
      },
    },
    total: {
      immediate: true,
      handler() {
        this.mTotal = this.total;
      },
    },

    mCurrentPage() {
      this.$emit("update:current-page", this.mCurrentPage);
    },
    mPageSize() {
      this.$emit("update:page-size", this.mPageSize);
    },
    mTotal() {
      this.$emit("update:total", this.mTotal);
    },
  },
  methods: {
    /**
     * @public 初始化方法，重置分页，这个不会抛出事件
     */
    init() {
      this.mCurrentPage = 1;
      // this.mPageSize = 10
    },

    // 设置当前页面跟总数
    setPage(current, total) {
      this.mCurrentPage = current;
      this.mTotal = total;
    },

    handleSizeChange(event) {
      this.mPageSize = event;
      this.mCurrentPage = 1;

      this.$emit("size-change", event);

      this.$nextTick(() => {
        this.$emit("page-change", {
          currPage: this.mCurrentPage,
          size: this.mPageSize,
        });
      });
    },
    handleCurrentChange(event) {
      this.mCurrentPage = event;

      this.$emit("current-change", event);

      this.$nextTick(() => {
        this.$emit("page-change", {
          currPage: this.mCurrentPage,
          size: this.mPageSize,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-page {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
</style>
