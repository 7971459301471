<template>
  <div class="AppHeader" :style="{ height: `${headerHeight}px` }">
    <div class="header-tabbar-wrap">
      <img class="aside-img" src="icon.png" />
    </div>
    <!-- 右侧用户信息，未读消息和各个tool -->
    <div class="flex-middle user-menu-wrap">
      <p class="question-text" @click="goStatistics">后台管理系统</p>
      <el-divider direction="vertical" class="m-r"></el-divider>
      <!-- 全屏 -->
      <!-- <svg-icon
        icon-class="fullscreen"
        class-name="header-logo m-r"
        @click="changeFullscreen"
      />
      <el-divider direction="vertical" class="m-r"></el-divider> -->
      <!-- 头像角标+下拉 -->
      <div class="flex-middle m-r">
        <div class="block">
          <el-avatar :size="30" :src="pathUrl"></el-avatar>
        </div>
        <span class="welcome-name">
          <span>{{ userName }}</span>
          <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
        </span>
      </div>
      <el-dropdown>
        <i class="el-icon-setting icon-i"></i>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown-menu">
            <!-- <el-dropdown-item @click.native="changePassword">
              修改密码
            </el-dropdown-item> -->
            <el-dropdown-item @click.native="logout"> 退出 </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    headerHeight: {
      type: Number,
      default: () => 60,
    },
  },
  components: {
    // ArrowDown,
  },
  computed: {
    ...mapState(["USER"]),
    userName() {
      return this.USER.user_nickname || "admin";
    },
    pathUrl() {
      return (
        this.USER.url ||
        `https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg`
      );
    },
  },
  methods: {
    // ...mapMutations(["setToken", "setUser"]),
    /**
     * @description: 退出登录
     * @param {*}
     * @return {*}
     */
    logout() {
      // this.setToken(null);
      // this.setUser({})
      // this.$router.push("/login");
      this.$logout();
    },

    /**
     * @function 往组件外传递打开修改密码弹窗的事件
     */
    changePassword() {
      this.$emit("show-change-password");
    },
    /**
     * @function 切换全屏状态
     */
    changeFullscreen() {
      if (document.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      } else {
        const docElm = document.documentElement;
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        }
      }
    },

    //跳转大屏
    goStatistics() {
      window.open('https://admin.109yxds.com/')
    }
  },
};
</script>

<style lang="scss" scoped>
.icon-i {
  font-size: 18px;
  cursor: pointer;
  color: #8291a9;
  margin: 0 8px;
}
.user-dropdown-menu {
  width: 160px;
}
.flex-middle {
  display: flex;
  align-items: center;
}
.AppHeader {
  padding: 0 30px 0 20px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 5px 2px 6px rgba(15, 34, 5, 0.12);
  position: relative;
  z-index: 3;
  .header-tabbar-wrap {
    height: 70px;
    // ↓这个是为了防止子元素宽度超出
    overflow: hidden;
    flex-grow: 2;
    img{
      height: 100%;
    }
  }

  .user-menu-wrap {
    flex-shrink: 0;
    .header-logo {
      width: 16px;
    }
    .question-text {
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #8291a9;
      cursor: pointer;
    }
  }

  .header-name {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #e1bc29;
    line-height: 25px;
    white-space: nowrap;
  }

  .header-bell {
    width: 16px;
    height: auto;
    margin-right: 14px;
    cursor: pointer;
  }
  .welcome-name {
    display: inline-flex;
    align-items: center;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    .el-icon-arrow-down {
      font-weight: 600;
    }
    // color: #fff;
    span {
      // min-width: 90px;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
