/*
 * @Description: 公共参数
 * @FilePath: \vue2\src\config.js
 */

module.exports = {
  system: {
    titleName: "易零玖管理",
    icon: "logo.png", //菜单图标
    loginIcon: "logo.png", //登录页图标
  },
  headerToken: {
    tokenName: "XX-Token",
  },
  //接口状态码
  apiCode: {
    errorToken: 405, //token验证异常
    noApis: 404, //不存接口地址
    normal: 1, //正常状态
    error: 0, //错误状态
    noLogin: 402, //未登录 || 登录过期
    empower: 4300, //未授权
  },
  //接口白名单
  whiteList: [],
  //接口超时时间
  timeout: 10000,
};
