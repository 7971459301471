<template>
  <div class="home">
    <div :style="{ display: 'flex', height: '100%', alignContent: 'center' }">
      <OsSidebar
        :width="isCollapse ? asideCollapseWidth : asideWidth"
        :isCollapse="isCollapse"
        @collaps="isCollapse = true"
      ></OsSidebar>
      <div
        class="content-wrap"
        :style="{
          width: `calc(100vw - ${
            isCollapse ? asideCollapseWidth : asideWidth
          }px)`,
        }"
      >
        <i
          class="el-icon-s-unfold"
          :style="{
            'line-height': `${headerHeight}px`,
          }"
          v-if="isCollapse"
          @click="isCollapse = false"
        ></i>
        <OsHeader
          :headerHeight="headerHeight"
          :style="headerStyle"
          @show-change-password="isShowChangePasswordDialog = true"
        ></OsHeader>
        <div
          id="page-container"
          class="page-container wrapper-md"
          :style="{ height: `calc(100% - ${headerHeight}px)` }"
        >
          <router-view />
        </div>
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <ChangePasswordDialog
      :show.sync="isShowChangePasswordDialog"
    ></ChangePasswordDialog>
  </div>
</template>

<script>
import OsSidebar from "@/components/OsSidebar.vue";
import OsHeader from "@/components/OsHeader.vue";
import ChangePasswordDialog from "@/components/ChangePasswordDialog.vue";
export default {
  name: "home",
  components: { OsSidebar, OsHeader, ChangePasswordDialog },
  data() {
    return {
      headerHeight: 70,
      asideWidth: 250,
      asideCollapseWidth: 70,
      isCollapse: false,
      isShowChangePasswordDialog: false,
    };
  },
  computed: {
    headerStyle() {
      if (!this.isCollapse) {
        return {
          // width: '100%',
        };
      } else {
        return {
          // width: 'calc(100% - 52px})',
          "border-left": "42px solid transparent",
        };
      }
    },
  },
  mounted() {
    // this.$http.onResCode(RES_CODES.PasswordExpired, () => {
    //   this.isShowChangePasswordDialog = true;
    // });
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100vh;
  .content-wrap {
    position: relative;
    min-width: 768px;
    ::v-deep  .el-icon-s-unfold {
      font-size: 21px;
      position: absolute;
      left: 20px;
      z-index: 4;
      cursor: pointer;
      color: #565b6b;
    }
  }
  .page-container {
    box-sizing: border-box;
    height: 100%;
    background-color: #f5f5f5;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;

    .page-contant-wrap {
      position: relative;
      height: calc(100% - 36px);
    }

    .page-home {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 5px;
      box-shadow: 5px 2px 6px #0f22051f;
    }
  }
}
</style>
