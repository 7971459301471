import { render, staticRenderFns } from "./OsSearch.vue?vue&type=template&id=4c7cf152&scoped=true"
import script from "./OsSearch.vue?vue&type=script&lang=js"
export * from "./OsSearch.vue?vue&type=script&lang=js"
import style0 from "./OsSearch.vue?vue&type=style&index=0&id=4c7cf152&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7cf152",
  null
  
)

export default component.exports