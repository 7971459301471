<template>
  <div class="wrap" :style="wrapStyle">
    <div class="aside-logo" ref="asideLogo">
      <img class="aside-img" :src="system.icon" />
      <span v-if="!isCollapse" class="aside-title">{{ system.titleName }}</span>
      <i class="el-icon-s-fold" v-if="!isCollapse" @click="$emit('collaps')"></i>
    </div>

    <div :style="memuStyle">
      <el-scrollbar style="height: 100%">
        <el-menu :default-active="$route.path" ref="menu" unique-opened :collapse="isCollapse"
          class="el-menu-vertical-com" :class="{
            isCollapse,
          }" :collapse-transition="false" text-color="#ffffff" active-text-color="#ffffff"
          :background-color="isCollapse ? '#1e2740' : ''">
          <el-menu-item index="/welcome" @click="openPage({ node: '/welcome' })">
            <i class="el-icon-s-flag"></i>
            <span slot="title">欢迎页</span>
          </el-menu-item>
          <el-menu-item index="/merchant" @click="openPage({ node: '/merchant' })">
            <i class="el-icon-s-order"></i>
            <span slot="title">号商</span>
          </el-menu-item>
          <el-menu-item index="/binding" @click="openPage({ node: '/binding' })">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单绑定</span>
          </el-menu-item>
          <el-menu-item index="/goods" @click="openPage({ node: '/goods' })">
            <i class="el-icon-s-order"></i>
            <span slot="title">商品列表</span>
          </el-menu-item>
          <el-menu-item index="/prove" @click="openPage({ node: '/prove' })">
            <i class="el-icon-s-order"></i>
            <span slot="title">包赔证明</span>
          </el-menu-item>
          <el-submenu index="agent">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>推荐码</span>
            </template>
            <el-menu-item index="/agent" @click="openPage({ node: '/agent' })">
              <span slot="title">推荐人员</span>
            </el-menu-item>
            <el-menu-item index="/agentGoods" @click="openPage({ node: '/agentGoods' })">
              <span slot="title">商品推荐码</span>
            </el-menu-item>
            <el-menu-item index="/agentOrder" @click="openPage({ node: '/agentOrder' })">
              <span slot="title">订单推荐码</span>
            </el-menu-item>
            <el-menu-item index="/agentRecord" @click="openPage({ node: '/agentRecord' })">
              <span slot="title">佣金流水</span>
            </el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="/agent" @click="openPage({ node: '/agent' })">
            <i class="el-icon-s-order"></i>
            <span slot="title">总代理</span>
          </el-menu-item> -->
          <!-- <el-submenu index="/order">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>订单</span>
            </template>
            <el-menu-item index="/binding" @click="openPage({ node: '/binding' })">
              <span slot="title">订单绑定</span>
            </el-menu-item>
          </el-submenu> -->

          <el-submenu index="deposit">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>定金(押金)</span>
            </template>
            <el-menu-item index="/depositList" @click="openPage({ node: '/depositList' })">
              <span slot="title">流水记录</span>
            </el-menu-item>
            <el-menu-item index="/depositCash" @click="openPage({ node: '/depositCash' })">
              <span slot="title">提现受理</span>
            </el-menu-item>
          </el-submenu>

          <!-- <el-menu-item index="/setUser" @click="openPage({ node: '/setUser' })">
            <i class="el-icon-s-tools"></i>
            <span slot="title">用户设置</span>
          </el-menu-item> -->
        </el-menu>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import { system } from "@/config";
import { mapState, mapMutations } from "vuex";

export default {
  mixins: [],
  data() {
    return {
      isMounted: false,
    };
  },
  props: {
    width: {
      type: Number,
      default: () => 200,
    },
    isCollapse: {
      require: false,
      type: Boolean,
      default: false,
    },
  },
  components: {},
  watch: {},
  computed: {
    ...mapState(["USER"]),
    system() {
      return system;
    },
    wrapStyle() {
      if (this.isCollapse) {
        return {
          width: `${this.width}px`,
          "background-color": `#1e2740`,
        };
      } else {
        return {
          width: `${this.width}px`,
          "background-color": `#212C38`,
          "background-size": "cover",
        };
      }
    },
    memuStyle() {
      if (!this.isCollapse && this.isMounted) {
        let logoHeight = this.$refs.asideLogo?.clientHeight;
        return {
          height: `calc(100% - ${logoHeight}px - 40px)`,
        };
      } else {
        return {
          height: "100%",
          // 'margin-top': '20px',
        };
      }
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    // 点击收起按钮
    collapseClickEvent() {
      this.$emit("collaps");
    },
    updateUrl(url) {
      this.$refs.menu.updateActiveIndex(url);
    },
    openPage({ node, isOpenTab, isChildren }) {
      // return
      let _url = node // || this.$route.path;
      if (isOpenTab) {
        let _fullpath = window.location.href;
        let toUrl = _fullpath.replace(this.$route.fullPath, "");
        window.open(toUrl + node, "_blank");
      } else if (isChildren) {
        this.$refs.menu.updateActiveIndex(_url);
      } else if (_url !== this.$route.path) {
        this.$router.push(node);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$--color-active-color: rgb(255, 255, 255); // 选中字体主题色
$--color-active-background: #171d2552; // 选中/展开 背景色
$--color-collapse-btn: rgb(1, 1, 1); // 收起/展开 按钮颜色
$--color-transparent: rgba(0,
    0,
    0,
    0); // 请不要修改！！默认菜单有颜色，用这个设置成透明。保持跟侧边栏一致
$--color-icon-hover: #0081ff; // 收起的时候，icon hover颜色

::v-deep .el-icon-s-fold {
  font-size: 21px;
  line-height: 42px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  color: $--color-active-color;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  padding-bottom: 32px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  flex-shrink: 0;

  .menu-icon {
    width: 16px;
    height: 16px;
    margin-right: 13px;
    font-size: 18px;
  }

  .aside-logo {
    height: 70px;
    padding: 0px 10px 0px 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #161d26;
    position: relative;
    align-items: center;

    &.isCollapseLogo {
      padding: 0;
      // margin-top: 14px;
      justify-content: center;
    }

    .aside-img {
      width: 40px;
      height: 40px;
    }

    .aside-title {
      font-size: 18px;
      line-height: 18px;
      margin-left: 10px;
      color: #fff;
      color: $--color-active-color;
    }
  }

  .el-menu-vertical-com {
    background-color: $--color-transparent;
  }

  .aside-bottom-image {
    width: 100%;
    height: 145px;
    position: absolute;
    bottom: 0;
    left: 0;

    img {
      width: 100%;
      height: 145px;
    }
  }
}

::v-deep .el-menu {
  border-right-width: 0px;
  background-color: transparent;

  &>.el-submenu.is-opened>.el-submenu__title {
    background: $--color-active-background;
    // margin: 0 10px;
    color: $--color-active-color !important;

    &>.menu-icon {
      color: #0081ff;
    }
  }

  // 字体加粗
  &.el-menu-vertical-com {

    &>.el-submenu>.el-submenu__title,
    &>.el-menu-item {
      // margin: 0 10px;
    }
  }

  // 未选择状态
  .el-menu-item,
  .el-submenu .el-submenu__title,
  .el-submenu .el-menu-item {
    height: 60px;
    line-height: 60px;
  }

  .el-menu-item,
  .el-submenu .el-submenu__title {
    text-align: left;
  }

  .el-submenu .el-menu-item {
    text-align: left;
  }

  // 这边的 submenu__title 跟 el-menu-item都要加上，否则会导致字跟图标不对齐
  .el-submenu__title,
  .el-menu-item {
    border-left: 4px solid transparent;
    display: flex;
    align-items: center;
  }

  &>.el-menu-item.is-active {
    background: $--color-active-background !important;

    // margin: 0 10px;
    .menu-icon {
      color: #0081ff;
    }
  }

  &.el-menu-vertical-com>.el-menu-item.is-active {
    // padding-left: 0px !important;
    // padding-right: 0px !important;
    border-left: 5px solid #0081ff;

    .menu-icon {
      color: #0081ff;
      margin-left: 20px;

      &.isCollapse {
        margin-left: 0;
      }
    }
  }

  .is-active>.el-submenu__title {
    border-left: 5px solid #0081ff;

    .menu-icon {
      color: #0081ff;
      margin-left: 20px;

      &.isCollapse {
        margin-left: 0;
      }
    }
  }

  .el-submenu__title:hover,
  .el-menu-item:hover {
    background-color: $--color-transparent;
    color: $--color-active-color !important;
  }

  .el-menu-item:focus {
    background-color: $--color-transparent;
    color: rgb(140, 152, 187);
  }

  .el-submenu .el-menu-item {
    color: $--color-active-color;
    padding-left: 50px !important;

    &.is-active {
      // background-color: rgba(255, 255, 255, 0.3);
      border-left: 4px solid transparent;
      padding-left: 50px !important;

      .menu-icon {
        color: #0081ff;
      }
    }
  }

  // 多层级
  .el-submenu .el-submenu {
    // margin: 0 10px;
    padding-left: 0 !important;

    .el-submenu__title {
      border-left: 0 !important;
      padding: 0 !important;
      padding-left: 54px !important;
      margin: 0 !important;
    }

    .el-menu-item {
      margin: 0 !important;
      border-left: 0 !important;
      padding-left: 74px !important;
    }
  }
}

// 收起样式
::v-deep .el-menu-vertical-com.isCollapse {

  .el-tooltip:hover,
  .el-submenu__title:hover,
  .el-menu-item:hover {
    background: $--color-transparent !important;
    background-color: $--color-transparent !important;
    color: $--color-icon-hover !important;

    svg {
      color: $--color-icon-hover;
    }
  }

  .el-submenu__title,
  .el-menu-item {
    margin: 0 !important;
  }

  i {
    font-size: 20px;
  }
}

::v-deep .isCollapse {
  .el-submenu__title {
    display: inline-block;
  }
}
</style>
<style lang="scss">
.el-menu--vertical {
  .el-menu--popup {
    .el-menu-item:hover {
      background-color: #20273e !important;
      font-weight: 600 !important;
      color: #fff !important;
    }
  }
}
</style>
