<template>
  <div>
    <el-dialog
      title="修改密码"
      :visible.sync="mShow"
      class="common-dialog"
      top="20vh"
      :modal-append-to-body="true"
      :append-to-body="true"
      @opened="openDialog"
      :close-on-click-modal="false"
      width="450px"
    >
      <el-form
        label-width="120px"
        size="mini"
        :rules="rules"
        ref="form"
        :model="form"
      >
        <el-col :span="24">
          <el-form-item label="用户名：" prop="userName">
            <el-input
              placeholder="请输入用户名"
              v-model.trim="form.userName"
              size="mini"
              clearable
              :maxlength="30"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="昵称：" prop="nickName">
            <el-input
              placeholder="请输入昵称"
              v-model.trim="form.nickName"
              size="mini"
              clearable
              :maxlength="30"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="旧密码：" prop="oldPass">
            <el-input
              v-model.trim="form.oldPass"
              size="mini"
              placeholder="请输入旧密码"
              :readonly="form.passwordReadOnly"
              clearable
              type="password"
              :maxlength="50"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="password-wrap">
          <el-form-item label="新密码：" prop="newPass">
            <el-input
              v-model.trim="form.newPass"
              size="mini"
              placeholder="请输入新密码"
              :readonly="form.passwordReadOnly"
              clearable
              type="password"
              :maxlength="50"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="确认密码：" prop="confirmPassword">
            <el-input
              v-model.trim="form.confirmPassword"
              size="mini"
              placeholder="请输入确认密码"
              :readonly="form.passwordReadOnly"
              clearable
              type="password"
              :maxlength="50"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!-- {{ USERID }} -->
      <template #footer>
        <div class="common-dialog-footer">
          <el-button size="small" @click="mShow = false">关闭</el-button>
          <el-button size="small" type="primary" @click="save">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { createRequiredRule } from "@/utill/rules.js";
// import apis from "@/apis";
import { mapState } from "vuex";

class PasswordForm {
  constructor({ userName = "", nickName = "" }) {
    this.oldPass = "";
    this.newPass = "";
    this.confirmPassword = "";
    this.userName = userName;
    this.nickName = nickName;
    this.passwordReadOnly = true;
  }
}

export default {
  components: {},
  props: {
    // 是否显示弹窗
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: new PasswordForm({}),
      mShow: this.show,
    };
  },
  computed: {
    ...mapState(["USER"]),
    rules() {
      return {
        userName: [createRequiredRule("用户名")],
        oldPass: [createRequiredRule("旧密码")],
        newPass: [createRequiredRule("新密码")],
        confirmPassword: [createRequiredRule("确认密码")],
      };
    },
  },
  watch: {
    show() {
      this.mShow = this.show;

      // 弹窗关闭时，清空表单
      if (!this.mShow) {
        this.form = new PasswordForm({});
        this.$refs.form.resetFields();
      } else {
        this.form = new PasswordForm({
          userName: this.USER.name,
          nickName: this.USER.nick_name,
        });
      }
    },

    mShow() {
      this.$emit("update:show", this.mShow);
    },
  },

  methods: {
    // 弹窗打开后的回调
    openDialog() {
      setTimeout(() => {
        this.form.passwordReadOnly = false;
      }, 300);
    },
    // 保存之前的校验
    beforeSave() {
      if (this.form.newPass !== this.form.confirmPassword) {
        this.$message.error("请确保新密码和确认密码一致!");
        return false;
      }
      return true;
    },

    save() {
      this.$refs.form.validate((result) => {
        if (!result) {
          return;
        }

        if (!this.beforeSave()) {
          return;
        }

        const arg = {
          name: this.form.userName,
          password: this.form.newPass,
          oldPassword: this.form.oldPass,
          nickName: this.form.nickName,
        };
        this.$http.post(this.$apis.user_updateUser, arg).then((res) => {
          if (res.data.code !== 200) {
            return;
          }
          this.$message({
            message: res.data.msg,
            type: "success",
            duration: 2000,
          });
          this.mShow = false;
          this.$emit("complete");

          const than = this;
          let tim = setTimeout(() => {
            than.$logout();
            clearTimeout(tim);
          }, 2000);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .common-dialog .content-wrap {
  overflow-x: hidden;
  .el-form-item {
    margin-bottom: 30px;
  }
  .mask {
    z-index: 3001;
    width: 100vw;
  }
}
::v-deep .password-wrap .el-form-item--mini.el-form-item.is-error {
  margin-bottom: 30px;
}
</style>
