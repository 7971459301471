<script>
import { Form } from 'element-ui'

export default {
  mixins: [Form],
  setup: Form.setup,
  props: {
    labelWidth: {
      type: String,
      default: () => '100px',
    },
    size: {
      type: String,
      default: () => 'mini',
    },
  },
}
</script>
